import React from "react";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Metas from "../../../models/metas.js";
import { withNamespaces, Interpolate } from "react-i18next";
import Textarea from "react-textarea-autosize";
// import plan_post from '../../../../assets/images/i010-plan_01.svg';
import plan_hire from "../../../../assets/images/i011-plan_02.svg";
import plan_hire_plus from "../../../../assets/images/i012-plan_03.svg";
import { CSSTransitionGroup } from "react-transition-group";
import { Link } from "react-router-dom";
import Employer from "../../../models/employer";
import icon_download from "../../../../assets/images/icon/icon_24_download_B100.svg";
import i18n from "../../../../i18n";
import welcome_banner_img_hp_en from "../../../../assets/images/i037-splash2.svg";
import welcome_banner_img_hp_zh from "../../../../assets/images/i040-splash2_TC.svg";
import welcome_banner_img_ug_en from "../../../../assets/images/i039-splash4.svg";
import welcome_banner_img_ug_zh from "../../../../assets/images/i042-splash4_TC.svg";
import { page_smothly_scroll_to } from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";
import ThreeBasicRules from "../../general/ThreeBasicRules";
import Slider from "react-slick/lib";
import modal_pop_up_assure from "../../../../assets/images/i049-pop-ups-assure.svg";
import DeviceDetector from "../../../models/device_detector";
import downloadjs from "downloadjs";

const config = new Config();
const headers = new ApiHeaders();

class UpgradePlansPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.checkPendingData = this.checkPendingData.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);

    this.contactMeetJobs = this.contactMeetJobs.bind(this);
    // this.handleTeaxareaScroll = this.handleTeaxareaScroll.bind(this);
    this.handleActiveStep = this.handleActiveStep.bind(this);
    this.handleAgreementChange = this.handleAgreementChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // actions bar
    this.discard = this.discard.bind(this);
    this.cancelTheAction = this.cancelTheAction.bind(this);
    this.upgradePlan = this.upgradePlan.bind(this);
    this.upgradePlanWithoutContract = this.upgradePlanWithoutContract.bind(
      this
    );
    this.continueSubmitForm = this.continueSubmitForm.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.state = {
      active_company_id: -1,
      plan_checked: "",
      original_plan_checked: "",
      steps: {
        active: 1,
        1: { visited: true },
        2: { visited: false },
      },
      agreement_checked: false,
      has_pending_data: false,
      plan_types: {},
      message_modal: {
        open: false,
        text: "",
        way: null,
      },
      error_messages: {},
      loading: true,
      updating: false,
    };
    this.slider_settings = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 3,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            speed: 500,
            dots: false,
            arrows: false,
            infinite: false,
            centerMode: true,
            centerPadding: "185px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 720,
          settings: {
            speed: 500,
            dots: false,
            arrows: false,
            infinite: false,
            centerMode: true,
            centerPadding: "160px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            speed: 500,
            dots: false,
            arrows: false,
            infinite: false,
            centerMode: true,
            centerPadding: "105px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            speed: 500,
            dots: false,
            arrows: false,
            infinite: false,
            centerMode: true,
            centerPadding: "36px",
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.company_id = this.props.company_id;
    this.setState({ loading: true });
    let options = {
      method: "GET",
      url: config.api_url("/employers/plan_types"),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          this.setState(
            {
              plan_types: new Metas("plan_types", response.data),
            },
            () =>
              this.updateActiveCompany(
                this.props.current_user.active_company.id
              )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            loading: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (
      this.props.current_user.active_company.id !==
      next_props.current_user.active_company.id
    ) {
      this.updateActiveCompany(next_props.current_user.active_company.id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateActiveCompany(company_id) {
    const { current_user } = this.props;
    if (
      current_user.employer_roles[company_id].access_control_list
        .plan_manageable
    ) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          active_company_id: company_id,
          access_control_list:
            current_user.employer_roles[company_id].access_control_list,
          loading: true,
        }),
        () => this.fetchData()
      );
    } else {
      this.setState((prev_state) => ({
        ...prev_state,
        active_company_id: company_id,
        access_control_list:
          current_user.employer_roles[company_id].access_control_list,
        loading: false,
      }));
    }
  }

  fetchData() {
    let options = {
      method: "GET",
      url: config.api_url(
        `/employers/${this.state.active_company_id}?include=addresses,photos`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        const employer = new Employer(response.data);
        this.props.updateCurrentUserActiveCompany({ ...employer });

        if (this._isMounted) {
          const raw_latest_plan = employer.latest_plan;

          let plan_key = "";
          if (raw_latest_plan) {
            plan_key = this.state.plan_types.key(raw_latest_plan.name);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            plan_checked: plan_key,
            original_plan_checked: plan_key,
            has_pending_data: false,
            loading: false,
          }));
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            loading: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  checkPendingData() {
    this.setState((prev_state) => ({
      ...prev_state,
      has_pending_data:
        prev_state.plan_checked !== prev_state.original_plan_checked,
      steps: {
        ...prev_state.steps,
        2: {
          visited: prev_state.plan_checked !== prev_state.original_plan_checked,
        },
      },
    }));
  }

  handlePlanChange(e, value) {
    this.setState(
      {
        plan_checked: value,
      },
      () => this.checkPendingData()
    );
  }

  contactMeetJobs(e) {
    e.preventDefault();
    this.props.updateContactMeetJobsModal(true);
  }

  // handleTeaxareaScroll = e => {
  //     e.preventDefault();
  //     let { scrolled_to_bottom } = this.state.upgrade_message_modal;
  //     if(!scrolled_to_bottom) {
  //         const elements =  document.querySelectorAll("#contract_content");
  //         if(elements[0]) {
  //             if(Math.abs(elements[0].scrollHeight - (elements[0].offsetHeight + elements[0].scrollTop)) <= 10 ) {
  //                 scrolled_to_bottom = true;
  //             }
  //         }
  //
  //         this.setState((prev_state) => ({
  //             ...prev_state,
  //             upgrade_message_modal: {
  //                 ...prev_state.upgrade_message_modal,
  //                 scrolled_to_bottom,
  //             }
  //         }));
  //     }
  // };

  handleActiveStep(e, new_step) {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      steps: {
        ...prev_state.steps,
        active: new_step,
        [new_step]: { visited: true },
      },
    }));
    page_smothly_scroll_to(0, 300);
  }

  handleAgreementChange = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      agreement_checked: !prev_state.agreement_checked,
    }));
  };

  // actions bar

  discard = (e) => {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        plan_checked: prev_state.original_plan_checked,
      }),
      () => this.checkPendingData()
    );
  };

  cancelTheAction = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      message_modal: {
        open: false,
        text: "",
        way: null,
      },
    }));
  };

  upgradePlan = (e) => {
    e.preventDefault();
    const { t } = this.props;
    this.setState((prev_state) => ({
      ...prev_state,
      message_modal: {
        open: true,
        text: t("would_you_like_to_upgrade_your_plan_"),
        way: null,
      },
    }));
  };

  upgradePlanWithoutContract = (e) => {
    e.preventDefault();
    const { t } = this.props;
    this.setState((prev_state) => ({
      ...prev_state,
      message_modal: {
        open: true,
        text: t(
          "are_you_sure_to_upgrade_your_service_plan_and_accept_3_basic_rules_"
        ),
        way: "green_channel",
      },
    }));
  };

  continueSubmitForm = (e) => {
    e.preventDefault();
    const { active_company_id, plan_checked, updating } = this.state;
    const way = this.state.message_modal.way;
    if (!updating) {
      this.setState({ updating: true });
      let formData = new FormData();
      formData.append("employer[choose_plan]", plan_checked);
      if (way === "green_channel")
        formData.append("contract[rule_type]", "three_basic");
      else formData.append("contract[rule_type]", "complete");

      let options = {
        method: "PUT",
        url: config.api_url(`/employers/${active_company_id}`),
        headers: headers.getItemsFromLocalStorage("multipart/form-data"),
        data: formData,
        json: true,
      };
      axios(options)
        .then((response) => {
          headers.updateItemsToLocalStorage(response.headers);
          this.props.updateConnectionOffModal(false);

          if (this._isMounted) {
            options = {
              method: "GET",
              url: config.api_url(
                `/employers/${this.state.active_company_id}?include=addresses,photos`
              ),
              headers: headers.getItemsFromLocalStorage(),
              json: true,
            };

            return axios(options);
          }
        })
        .then((response) => {
          headers.updateItemsToLocalStorage(response.headers);
          this.props.updateConnectionOffModal(false);

          const employer = new Employer(response.data);
          this.props.updateCurrentUserActiveCompany({ ...employer });

          if (this._isMounted) {
            this.setState(
              (prev_state) => ({
                ...prev_state,
                steps: {
                  ...prev_state.steps,
                  active: 1,
                },
                agreement_checked: false,
                has_pending_data: false,
                message_modal: {
                  open: false,
                  text: "",
                  way: null,
                },
                error_messages: {},
                updating: false,
              }),
              () => {
                const internal_uri = new InternalUri();
                this.props.history.push(
                  internal_uri.formatted_dashboard_index_page_path()
                );

                if (way === "green_channel") {
                  this.props.updateWelcomeEmployerModal(
                    true,
                    i18n.language === "zh-TW"
                      ? welcome_banner_img_hp_zh
                      : welcome_banner_img_hp_en,
                    "we_have_placed_you_in_high_priority",
                    "we_will_reach_you_via_the_information_you_have_provided_",
                    "you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
                    null
                  );
                } else {
                  this.props.updateWelcomeEmployerModal(
                    true,
                    i18n.language === "zh-TW"
                      ? welcome_banner_img_ug_zh
                      : welcome_banner_img_ug_en,
                    "you_are_all_set",
                    "you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_",
                    null,
                    null
                  );
                }
              }
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            headers.updateItemsToLocalStorage(error.response.headers);
            if (error.response.status === 401) window.location.reload();
          }

          if (this._isMounted) {
            if (typeof error.response === "undefined") {
              window.location.reload();
              this.props.updateConnectionOffModal(true);
            }

            this.setState((prev_state) => ({
              ...prev_state,
              error_messages:
                error.response &&
                error.response.data &&
                error.response.data.status === "error"
                  ? error.response.data.errors
                  : {
                      full_message:
                        "There was an error. Please try again later.",
                    },
              updating: false,
            }));

            page_smothly_scroll_to(0, 300);
          }
        });
    }
  };

  handleDownload = (e, url) => {
    e.preventDefault();
    downloadjs(url);
  };

  render() {
    const {
      plan_checked,
      steps,
      agreement_checked,
      message_modal,
      updating,
    } = this.state;
    const {
      access_control_list,
      has_pending_data,
      original_plan_checked,
      loading,
      error_messages,
    } = this.state;
    const {
      handlePlanChange,
      contactMeetJobs,
      handleActiveStep,
      handleAgreementChange,
      discard,
      cancelTheAction,
      upgradePlan,
      upgradePlanWithoutContract,
      continueSubmitForm,
      handleDownload,
    } = this;
    const { t } = this.props;
    const internal_uri = new InternalUri();
    const device_detector = new DeviceDetector();

    if (loading) {
      return (
        <div className="dashboard-upgrade-plans">
          <div className="container-fluid">
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              {t("general:upgrade_plans")}
            </h6>
            <h2>{t("general:upgrade_plans")}</h2>
            <div className="inner-wrapper">{`${t("general:loading")}...`}</div>
          </div>
        </div>
      );
    }

    if (!access_control_list.plan_manageable) {
      return (
        <div className="dashboard-upgrade-plans">
          <div className="container-fluid">
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              {t("general:upgrade_plans")}
            </h6>
            <h2>{t("general:upgrade_plans")}</h2>
            <div className="inner-wrapper">
              {t(
                "general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_"
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard-upgrade-plans">
        <div className="container-fluid">
          <h6 className="breadcrumb">
            <Link to={internal_uri.formatted_dashboard_index_page_path()}>
              {t("general:home")}
            </Link>
            {" / "}
            {t("general:upgrade_plans")}
          </h6>
          {error_messages.full_message ? (
            <h6 className="message error-message">
              {error_messages.full_message}
            </h6>
          ) : (
            ""
          )}
          <h2>{t("general:upgrade_plans")}</h2>
          <div className="step-process-bar">
            {steps.active === 1 ? (
              <div className="step-process step-process-1 active">
                <p>1</p>
                <h6>{t("general:choose_plan")}</h6>
              </div>
            ) : (
              <div
                className="step-process step-process-1 selectable"
                onClick={(e) => handleActiveStep(e, 1)}
              >
                <p>1</p>
                <h6>{t("general:choose_plan")}</h6>
              </div>
            )}
            <div
              className={`line ${
                steps.active === 2 || steps.active === 3 ? "active" : ""
              }`}
            />
            {steps.active === 2 ? (
              <div className="step-process step-process-2 active">
                <p>2</p>
                <h6>{t("general:agreement")}</h6>
              </div>
            ) : steps[2].visited ? (
              <div
                className="step-process step-process-2 selectable"
                onClick={(e) => handleActiveStep(e, 2)}
              >
                <p>2</p>
                <h6>{t("general:agreement")}</h6>
              </div>
            ) : (
              <div className="step-process step-process-2">
                <p>2</p>
                <h6>{t("general:agreement")}</h6>
              </div>
            )}
          </div>
        </div>
        {steps.active === 1 ? (
          <div className="step-1">
            <div className="container-fluid">
              <h4>{t("general:choose_plan")}</h4>
            </div>
            <div className="container plans-container">
              <div className="plans-wrapper">
                <div className="plans">
                  <Slider {...this.slider_settings}>
                    <div className="plan-wrapper">
                      <input
                        name="plan"
                        id="post_referral"
                        value="post_referral"
                        checked={plan_checked === "post_referral"}
                        type="radio"
                        className="radio-input"
                        readOnly
                      />
                      <label
                        htmlFor="post_referral"
                        className={`radio-plan ${
                          original_plan_checked === "post_referral"
                            ? "current-plan"
                            : ""
                        }`}
                        onClick={(e) => {
                          if (
                            original_plan_checked !== "custom_referral" &&
                            original_plan_checked !== "social_referral"
                          ) {
                            return handlePlanChange(e, "post_referral");
                          }
                        }}
                      >
                        <div className="block plan plan-hire">
                          <h4>{t("general:plan_post")}</h4>
                          <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${plan_hire})` }}
                          ></div>
                          <div className="prices">
                            <h3>POSTING_PLAN_H3</h3>
                            <h5>POSTING_PLAN_H5</h5>
                          </div>
                          <hr />
                          {/*<h6>{t('general:everything_in_post__plus_')}</h6>*/}
                          <p>{t("general:exposure_160_countries")}</p>
                          <p>{t("general:seo_for_employer_page")}</p>
                          <p>
                            {t(
                              "general:friendly_interface_managing_recruitment"
                            )}
                          </p>

                          <div
                            className={`btn btn-larger btn-flat btn-hollow btn-call-to-action ${
                              original_plan_checked === "social_referral" ||
                              original_plan_checked === "custom_referral"
                                ? "disabled"
                                : ""
                            }`}
                          >
                            {original_plan_checked === "post_referral" ? (
                              <h5 className="content">
                                {t("general:current_plan")}
                              </h5>
                            ) : plan_checked === "post_referral" ? (
                              <h5 className="content">
                                {t("general:selected")}
                              </h5>
                            ) : (
                              <h5 className="content">
                                {t("general:select_plan")}
                              </h5>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="plan-wrapper">
                      <input
                        name="plan"
                        id="social_referral"
                        value="social_referral"
                        checked={plan_checked === "social_referral"}
                        type="radio"
                        className="radio-input"
                        readOnly
                      />
                      <label
                        htmlFor="social_referral"
                        className={`radio-plan ${
                          original_plan_checked === "social_referral"
                            ? "current-plan"
                            : ""
                        }`}
                        onClick={(e) => {
                          if (original_plan_checked !== "custom_referral") {
                            return handlePlanChange(e, "social_referral");
                          }
                        }}
                      >
                        <div className="block plan plan-hire">
                          <h4>{t("general:plan_hire")}</h4>
                          <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${plan_hire})` }}
                          ></div>
                          <div className="prices">
                            <h3>{t("general:_16__annual_salary")}</h3>
                            <h5>{t("general:per_successful_hire")}</h5>
                          </div>
                          <hr />
                          {/*<h6>{t('general:everything_in_post__plus_')}</h6>*/}

                          <p>
                            {t("general:commnuity_referral_quick_and_precise")}
                          </p>
                          <p>
                            {t(
                              "general:emplouyer_brandind_and_dynamic_promotion"
                            )}
                          </p>
                          <p>
                            {t(
                              "general:integrated_marketing_and_speed_interview_event"
                            )}
                          </p>

                          <div
                            className={`btn btn-larger btn-flat btn-hollow btn-call-to-action ${
                              original_plan_checked === "custom_referral"
                                ? "disabled"
                                : ""
                            }`}
                          >
                            {original_plan_checked === "social_referral" ? (
                              <h5 className="content">
                                {t("general:current_plan")}
                              </h5>
                            ) : plan_checked === "social_referral" ? (
                              <h5 className="content">
                                {t("general:selected")}
                              </h5>
                            ) : (
                              <h5 className="content">
                                {t("general:select_plan")}
                              </h5>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="plan-wrapper">
                      <input
                        name="plan"
                        id="custom_referral"
                        value="custom_referral"
                        checked={plan_checked === "custom_referral"}
                        type="radio"
                        className="radio-input"
                        readOnly
                      />
                      <label
                        htmlFor="custom_referral"
                        className={`radio-plan ${
                          original_plan_checked === "custom_referral"
                            ? "current-plan"
                            : ""
                        }`}
                        onClick={(e) => handlePlanChange(e, "custom_referral")}
                      >
                        <div className="block plan plan-custom">
                          <h4>{t("general:plan_hire_")}</h4>
                          <div
                            className="image-wrapper"
                            style={{
                              backgroundImage: `url(${plan_hire_plus})`,
                            }}
                          />
                          <div className="prices clearfix">
                            <h3>{t("general:_20__annual_salary")}</h3>
                            <h5>{t("general:per_successful_hire")}</h5>
                          </div>
                          <hr />
                          {/*<h6>{t('general:everything_in_hire__plus_')}</h6>*/}
                          {/*<p>{t('general:recruitment_consulting_service')}</p>*/}
                          <p>{t("general:everything_in_social_plan")}</p>
                          <p>{t("general:professional_hunter_search")}</p>
                          <p>{t("general:double_screening_ai")}</p>
                          <p>
                            {t(
                              "general:professional_slill_language_adaptive_tests"
                            )}
                          </p>
                          <p>{t("general:customized_service_contract")}</p>
                          <div className="btn btn-larger btn-flat btn-hollow btn-call-to-action">
                            {original_plan_checked === "custom_referral" ? (
                              <h5 className="content">
                                {t("general:current_plan")}
                              </h5>
                            ) : plan_checked === "custom_referral" ? (
                              <h5 className="content">
                                {t("general:selected")}
                              </h5>
                            ) : (
                              <h5 className="content">
                                {t("general:select_plan")}
                              </h5>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <p className="contact-text">
                <Interpolate
                  i18nKey="if_you_have_further_questions__please___meet_jobs_"
                  useDangerouslySetInnerHTML={true}
                  contact={
                    <button
                      className="link"
                      onClick={(e) => contactMeetJobs(e)}
                    >
                      {t("contact")}
                    </button>
                  }
                />
              </p>
            </div>

            <CSSTransitionGroup
              component="div"
              transitionName="actions-bar-slide"
              transitionEnterTimeout={200}
              transitionLeaveTimeout={100}
            >
              {has_pending_data ? (
                <div className="actions-bar-wrapper">
                  <div className="actions-bar">
                    <p>
                      {t(
                        "if_you_would_like_to_upgrade_your_plan__please_click_next_"
                      )}
                    </p>
                    <div className="buttons-wrapper">
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => discard(e)}
                      >
                        <h5>{t("general:cancel")}</h5>
                      </button>
                      <button
                        className="btn btn-larger btn-flat btn-hollow"
                        onClick={(e) => handleActiveStep(e, 2)}
                      >
                        <h5>{t("general:next")}</h5>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </CSSTransitionGroup>
          </div>
        ) : (
          ""
        )}
        {steps.active === 2 ? (
          <div className="step-2">
            <div className="container-fluid">
              {plan_checked === "social_referral" ||
              plan_checked === "custom_referral" ? (
                <section className="section-three-basic-rules">
                  <div className="desktop-wrapper">
                    <h4>{t("general:three_basic_rules")}</h4>
                    <ThreeBasicRules type={plan_checked} />
                  </div>
                </section>
              ) : (
                ""
              )}
              <section className="section-contract">
                <div className="desktop-wrapper">
                  <h4>
                    {t("general:employer_user_agreement")}
                    {device_detector.is_mobile_or_tablet() ? (
                      <a
                        className="btn btn-larger btn-float btn-hollow btn-with-icon"
                        href={`/public_assets/contracts/${t(
                          "general:contract_file_name"
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>
                          <img src={icon_download} alt="" />
                          {t("general:download")}
                        </h5>
                      </a>
                    ) : (
                      <button
                        className="btn btn-larger btn-float btn-hollow btn-with-icon"
                        onClick={(e) =>
                          handleDownload(
                            e,
                            `/public_assets/contracts/${t(
                              "general:contract_file_name"
                            )}`
                          )
                        }
                      >
                        <h5>
                          <img src={icon_download} alt="" />
                          {t("general:download")}
                        </h5>
                      </button>
                    )}
                  </h4>
                  <Textarea
                    className="textarea"
                    minRows={12}
                    maxRows={12}
                    value={t(
                      "frontend_employer_agreement_online_text:text_version"
                    )}
                    readOnly
                    id="contract_content"
                  />
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    id="accept-agreement"
                    checked={agreement_checked}
                    readOnly
                  />
                  <label
                    className="checkbox"
                    onClick={(e) => handleAgreementChange(e)}
                  >
                    {plan_checked === "social_referral" ||
                    plan_checked === "custom_referral" ? (
                      <p>
                        {t(
                          "general:i_accept_meet_jobs_3_basic_rules_and_employer_user_agreement"
                        )}
                      </p>
                    ) : (
                      <p>
                        {t(
                          "general:i_accept_meet_jobs_employer_user_agreement"
                        )}
                      </p>
                    )}
                  </label>
                </div>
              </section>
              <section className="section-go-create">
                <div className="desktop-wrapper">
                  {!agreement_checked &&
                  (plan_checked === "social_referral" ||
                    plan_checked === "custom_referral") ? (
                    <div className="buttons-wrapper">
                      <button
                        className="link use-first-link"
                        onClick={(e) => upgradePlanWithoutContract(e)}
                      >
                        <h5>{t("general:use_first__contract_later__")}</h5>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>

            <div className="actions-bar-wrapper">
              <div className="actions-bar">
                <p>
                  <Interpolate
                    i18nKey="are_you_sure_to_upgrade_to___plan_"
                    useDangerouslySetInnerHTML={true}
                    new_plan_name={t(`${plan_checked}_name`)}
                  />
                </p>
                <div className="buttons-wrapper">
                  <button
                    className="btn btn-larger btn-flat btn-fill"
                    onClick={(e) => handleActiveStep(e, 1)}
                  >
                    <h5>{t("general:back")}</h5>
                  </button>
                  {agreement_checked && !updating ? (
                    <button
                      className="btn btn-larger btn-flat btn-hollow"
                      onClick={(e) => upgradePlan(e)}
                    >
                      <h5>{t("general:upgrade")}</h5>
                    </button>
                  ) : (
                    <button
                      onClick={(e) => e.preventDefault()}
                      className="btn btn-larger btn-flat btn-hollow disabled"
                    >
                      <h5>{t("general:upgrade")}</h5>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <CSSTransitionGroup
          component="div"
          transitionName="modal-wrapper-with-modal-slide"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {message_modal.open ? (
            <div className="modal-wrapper double-confirm-modal-wrapper">
              <div className="modal-inner-wrapper">
                <div className="modal-bg" />
                <div className="modal double-confirm-modal with-pop-up-icon">
                  <div
                    className="image-wrapper"
                    style={{ backgroundImage: `url(${modal_pop_up_assure}` }}
                  />
                  <div className="content">
                    <h4 className="modal-title">{message_modal.text}</h4>
                    {error_messages.full_message ? (
                      <h6 className="message error-message">
                        {error_messages.full_message}
                      </h6>
                    ) : (
                      ""
                    )}
                    <div className="buttons-wrapper">
                      <button
                        className="btn btn-larger btn-flat btn-ghost"
                        onClick={(e) => cancelTheAction(e)}
                      >
                        <h5>{t("general:cancel")}</h5>
                      </button>
                      {message_modal.way === "green_channel" ? (
                        <button
                          className="btn btn-larger btn-flat btn-fill"
                          onClick={(e) => continueSubmitForm(e)}
                        >
                          <h5>{t("general:accept")}</h5>
                        </button>
                      ) : (
                        <button
                          className="btn btn-larger btn-flat btn-fill"
                          onClick={(e) => continueSubmitForm(e)}
                        >
                          <h5>{t("general:upgrade")}</h5>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withNamespaces([
  "dashboard_upgrade_plans_page",
  "general",
  "frontend_employer_agreement_online_text",
])(UpgradePlansPage);
